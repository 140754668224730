var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.saveForm.apply(null, arguments)}}},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Freigeschaltet","type":{ 'is-danger': _vm.$v.magazine.status.$error },"message":{
              '': _vm.submitted && _vm.$v.magazine.status.$error
            }}},[_c('b-switch',{attrs:{"true-value":"1","false-value":"0","size":"is-small"},model:{value:(_vm.$v.magazine.status.$model),callback:function ($$v) {_vm.$set(_vm.$v.magazine.status, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.magazine.status.$model"}}),_vm._v("ist freigeschaltet ")],1)],1)]),_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"imagebox"},[_c('label',[_vm._v("Bild (Format 2:1, z.B. 2000x1000 Pixel)")]),(_vm.largeImage)?_c('div',[_c('img',{staticClass:"image",attrs:{"src":_vm.largeImage}})]):_vm._e(),_c('b-field',{staticClass:"file"},[_c('b-upload',{attrs:{"loading":_vm.isUploading,"accept":".jpg,.png,.webp"},on:{"input":_vm.getAsyncData},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('a',{staticClass:"button is-default"},[_c('b-icon',{attrs:{"icon":"cloud_upload"}})],1)]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isUploading,"can-cancel":false,"loading-icon-size":"2rem"},on:{"update:active":function($event){_vm.isUploading=$event}}})],1)],1)])]),_vm._m(0),_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Überschrift deutsch","type":{ 'is-danger': _vm.$v.magazine.headline_de.$error },"message":{
              'Bitte geben Sie eine Überschrift ein!':
                _vm.submitted && _vm.$v.magazine.headline_de.$error
            }}},[_c('b-input',{model:{value:(_vm.$v.magazine.headline_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.magazine.headline_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.magazine.headline_de.$model"}})],1),_c('b-field',{attrs:{"label":"Teaser deutsch (max. 250 Zeichen)","type":{ 'is-danger': _vm.$v.magazine.teaser_de.$error },"message":{
              'Bitte geben Sie einen Text ein!':
                _vm.submitted && _vm.$v.magazine.teaser_de.$error
            }}},[_c('b-input',{model:{value:(_vm.$v.magazine.teaser_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.magazine.teaser_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.magazine.teaser_de.$model"}})],1),_c('b-field',{attrs:{"label":"Inhalt deutsch","type":{ 'is-danger': _vm.$v.magazine.html_de.$error },"message":{
              'Bitte geben Sie einen Inhalt ein!':
                _vm.submitted && _vm.$v.magazine.html_de.$error
            }}},[_c('vue-trix',{attrs:{"id":"editor_de"},model:{value:(_vm.$v.magazine.html_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.magazine.html_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.magazine.html_de.$model"}})],1)],1),_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"Überschrift englisch","type":{ 'is-danger': _vm.$v.magazine.headline_en.$error },"message":{
              'Bitte geben Sie eine Überschrift ein!':
                _vm.submitted && _vm.$v.magazine.headline_en.$error
            }}},[_c('b-input',{model:{value:(_vm.$v.magazine.headline_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.magazine.headline_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.magazine.headline_en.$model"}})],1),_c('b-field',{attrs:{"label":"Teaser english (max. 250 Zeichen)","type":{ 'is-danger': _vm.$v.magazine.teaser_en.$error },"message":{
              'Bitte geben Sie einen Text ein!':
                _vm.submitted && _vm.$v.magazine.teaser_en.$error
            }}},[_c('b-input',{model:{value:(_vm.$v.magazine.teaser_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.magazine.teaser_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.magazine.teaser_en.$model"}})],1),_c('b-field',{attrs:{"label":"Inhalt englisch","type":{ 'is-danger': _vm.$v.magazine.html_en.$error },"message":{
              'Bitte geben Sie einen Inhalt ein!':
                _vm.submitted && _vm.$v.magazine.html_en.$error
            }}},[_c('vue-trix',{attrs:{"id":"editor_de"},model:{value:(_vm.$v.magazine.html_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.magazine.html_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.magazine.html_en.$model"}})],1)],1)]),_c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":"verknüpfte Lots"}},[_c('b-taginput',{attrs:{"ellipsis":"","icon":"tags","placeholder":"Lotnummer hinzufügen","aria-close-label":"Lot löschen"},model:{value:(_vm.$v.magazine.auction_ids.$model),callback:function ($$v) {_vm.$set(_vm.$v.magazine.auction_ids, "$model", $$v)},expression:"$v.magazine.auction_ids.$model"}})],1)],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column field is-grouped"},[_c('router-link',{staticClass:"button",staticStyle:{"margin-right":"10px"},attrs:{"to":"/magazine/index","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('button',{staticClass:"button",attrs:{"role":"link"},on:{"click":navigate}},[_vm._v("Zurück zur Übersicht")])]}}])}),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","native-type":"submit","icon-left":"cloud_upload"}},[_vm._v("Speichern")])],1)])]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns view-columns"},[_c('div',{staticClass:"column"})])
}]

export { render, staticRenderFns }
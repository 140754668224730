<template>
  <div class="card">
    <div class="card-content">
      <div class="columns  view-columns">
        <div class="column">
          <div v-if="largeImage">
            <img :src="largeImage" class="image" />
          </div>
        </div>
      </div>

      <div class="columns  view-columns">
        <div class="column">
          <b-field label="Überschrift deutsch">
            {{ magazine.headline_de }}
          </b-field>
          <b-field label="Teaser deutsch">
            {{ magazine.teaser_de }}
          </b-field>
          <b-field label="Inhalt deutsch" v-html="magazine.html_de"> </b-field>
        </div>
        <div class="column">
          <b-field label="Überschrift englisch">
            {{ magazine.headline_en }}
          </b-field>
          <b-field label="Teaser englisch">
            {{ magazine.teaser_en }}
          </b-field>

          <b-field label="Inhalt englisch" v-html="magazine.html_en"> </b-field>
        </div>
      </div>
    </div>

    <div class="card-footer ">
      <div class="column field is-grouped">
        <router-link
          to="/magazine/index"
          class="button"
          style="margin-right: 10px;"
           custom
          v-slot="{ navigate }"
        >
          <button @click="navigate" role="link" class="button"
            >Zurück zur Übersicht</button
          ></router-link
        >

        <b-button
          @click.prevent="magazineEdit(magazine.id, magazine.headline_de)"
          class="button is-info"
          icon-left="square-edit-outline"
          style="margin-right: 10px;"
          >Bearbeiten</b-button
        >
        <b-button
          @click.prevent="magazineDelete(magazine.id, magazine.headline_de)"
          class="button is-danger"
          icon-left="delete_forever"
          >Löschen</b-button
        >
      </div>
    </div>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
export default {
  name: "magazineView",
  components: {},

  data: function() {
    return {
      isLoading: true,
      largeImage: null,
      magazine: {},
      error: false
    }
  },
  created: function() {
    this.magazineView(this.$route.params.id)
    this.$store.commit("setHeadline", {
      subheadline: "Magazin-Beitrag ansehen "
    })
  },

  methods: {
    magazineView: function(id) {
      var t = this
      t.$http({
        url: "/magazine/view/" + id,
        method: "get"
      })
        .then(function(resp) {
          t.magazine = resp.data.data
          if (t.magazine.image != null) {
            t.largeImage =
              t.$store.getters.config.page.imageBaseUrl +
              "/wines/magazine/" +
              t.magazine.image +
              "_l.jpg?"+Date.now();
          }
          t.isLoading = false
          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    },

    magazineDelete: function(id, name) {
      this.$parent.magazineDelete(id, name)
    },

    magazineEdit: function(id) {
      this.$router
        .push({
          path: "/magazine/edit/" + id
        })
        .catch((err) => {})
    }
  }
}
</script>
<style>
div#status,
div#customer {
  margin-bottom: 30px;
}
</style>

<template>
  <div class="card">
    <form method="post" @submit.prevent="saveForm">
      <div class="card-content">
        <div class="columns view-columns">
          <div class="column">
            <b-field
              label="Freigeschaltet"
              :type="{ 'is-danger': $v.magazine.status.$error }"
              :message="{
                '': submitted && $v.magazine.status.$error
              }"
            >
              <b-switch
                v-model.trim="$v.magazine.status.$model"
                true-value="1"
                false-value="0"
                size="is-small"
              >
              </b-switch
              >ist freigeschaltet
            </b-field>
          </div>
        </div>

        <div class="columns view-columns">
          <div class="column">
            <div class="imagebox">
              <label>Bild (Format 2:1, z.B. 2000x1000 Pixel)</label>
              <div v-if="largeImage">
                <img :src="largeImage" class="image" />
              </div>
              <b-field class="file">
                <b-upload
                  v-model="file"
                  :loading="isUploading"
                  @input="getAsyncData"
                  accept=".jpg,.png,.webp"
                >
                  <a class="button is-default">
                    <b-icon icon="cloud_upload"></b-icon>
                  </a>
                </b-upload>
                <b-loading
                  :is-full-page="false"
                  :active.sync="isUploading"
                  :can-cancel="false"
                  loading-icon-size="2rem"
                ></b-loading>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns  view-columns">
          <div class="column"></div>
        </div>
        <div class="columns  view-columns">
          <div class="column">
            <b-field
              label="Überschrift deutsch"
              :type="{ 'is-danger': $v.magazine.headline_de.$error }"
              :message="{
                'Bitte geben Sie eine Überschrift ein!':
                  submitted && $v.magazine.headline_de.$error
              }"
            >
              <b-input v-model.trim.lazy="$v.magazine.headline_de.$model">
              </b-input>
            </b-field>
            <b-field
              label="Teaser deutsch (max. 250 Zeichen)"
              :type="{ 'is-danger': $v.magazine.teaser_de.$error }"
              :message="{
                'Bitte geben Sie einen Text ein!':
                  submitted && $v.magazine.teaser_de.$error
              }"
            >
              <b-input v-model.trim.lazy="$v.magazine.teaser_de.$model">
              </b-input>
            </b-field>
            <b-field
              label="Inhalt deutsch"
              :type="{ 'is-danger': $v.magazine.html_de.$error }"
              :message="{
                'Bitte geben Sie einen Inhalt ein!':
                  submitted && $v.magazine.html_de.$error
              }"
            >
              <vue-trix
                id="editor_de"
                v-model.trim.lazy="$v.magazine.html_de.$model"
              ></vue-trix>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Überschrift englisch"
              :type="{ 'is-danger': $v.magazine.headline_en.$error }"
              :message="{
                'Bitte geben Sie eine Überschrift ein!':
                  submitted && $v.magazine.headline_en.$error
              }"
            >
              <b-input v-model.trim.lazy="$v.magazine.headline_en.$model">
              </b-input>
            </b-field>
            <b-field
              label="Teaser english (max. 250 Zeichen)"
              :type="{ 'is-danger': $v.magazine.teaser_en.$error }"
              :message="{
                'Bitte geben Sie einen Text ein!':
                  submitted && $v.magazine.teaser_en.$error
              }"
            >
              <b-input v-model.trim.lazy="$v.magazine.teaser_en.$model">
              </b-input>
            </b-field>

            <b-field
              label="Inhalt englisch"
              :type="{ 'is-danger': $v.magazine.html_en.$error }"
              :message="{
                'Bitte geben Sie einen Inhalt ein!':
                  submitted && $v.magazine.html_en.$error
              }"
            >
              <vue-trix
                id="editor_de"
                v-model.trim.lazy="$v.magazine.html_en.$model"
              ></vue-trix>
            </b-field>
          </div>
        </div>
        <div class="columns view-columns">
          <div class="column">
            <b-field label="verknüpfte Lots">
              <b-taginput
                v-model="$v.magazine.auction_ids.$model"
                ellipsis
                icon="tags"
                placeholder="Lotnummer hinzufügen"
                aria-close-label="Lot löschen"
              >
              </b-taginput>
            </b-field>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="column field is-grouped">
          <router-link
            to="/magazine/index"
            class="button"
            style="margin-right: 10px;"
             custom
          v-slot="{ navigate }"
        >
          <button @click="navigate" role="link" class="button"
            >Zurück zur Übersicht</button
          ></router-link
          >

          <b-button
            class="button"
            type="is-info"
            native-type="submit"
            icon-left="cloud_upload"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  numeric
} from "vuelidate/lib/validators"
import VueTrix from "vue-trix"
export default {
  name: "magazineForm",
  components: { VueTrix },
  data() {
    return {
      file: null,
      isUploading: false,
      largeImage: false,
      magazine: {
        image: "",
        headline_de: "",
        headline_en: "",
        teaser_de: "",
        teaser_en: "",
        html_de: "",
        html_en: "",
        status: 0,
        auction_ids: []
      },

      error: null,
      isLoading: false,
      submitted: false
    }
  },

  validations: {
    magazine: {
      headline_de: {
        required: required,
        minLength: minLength(5)
      },
      headline_en: {
        required: required,
        minLength: minLength(5)
      },
      teaser_de: {
        required: required,
        minLength: minLength(5),
        maxLength: maxLength(250)
      },
      teaser_en: {
        required: required,
        minLength: minLength(5),
        maxLength: maxLength(250)
      },
      html_de: {
        required: required,
        minLength: minLength(5)
      },
      html_en: {
        required: required,
        minLength: minLength(5)
      },
      image: {},
      auction_ids: {},
      status: { required }
    }
  },
  created: function() {
    var subheadline = ""

    var t = this
    if (typeof this.$route.params.id != "undefined") {
      subheadline = "Eintrag bearbeiten: #" + this.$route.params.id

      t.isLoading = true
      var t = this
      t.$http({
        url: "/magazine/view/" + this.$route.params.id,
        method: "get"
      })
        .then(function(resp) {
          t.magazine = resp.data.data
          if (t.magazine.image != null) {
            t.largeImage =
              t.$store.getters.config.page.imageBaseUrl +
              "/wines/magazine/" +
              t.magazine.image +
              "_l.jpg?"+Date.now();
          }
          t.isLoading = false
          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    } else {
      subheadline = "Neuen Eintrag anlegen"
    }

    this.$store.commit("setHeadline", {
      subheadline: subheadline
    })
  },

  methods: {
    saveForm: function() {
      //console.log("customer-daten")
      //console.log(this.page)
      this.error = null
      this.submitted = true
      this.$v.$touch()
      var t = this
      if (t.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        t.$parent.magazineSave(t.magazine)
      }
    },
    getAsyncData: debounce(function(file) {
      if (file == "") {
        return
      }

      var t = this
      this.isUploading = true
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function() {
        t.$http
          .post(
            "/magazine/imageupload",
            { image: reader.result },
            { timeout: 20000 }
          )
          .then(function(resp) {
            t.isUploading = false
            t.largeImage =
              t.$store.getters.config.page.imageBaseUrl +
              "/tmpuploads/" +
              resp.data.data +
              "_l.jpg"
            t.magazine.image = resp.data.data
          })
          .catch((error) => {
            t.data = []
            throw error
          })
          .finally(() => {
            t.isUploading = false
          })
      }
      reader.onerror = function(error) {
        //console.log("Error: ", error);
      }
    }, 50)
  }
}
</script>
<style>
#editor_de button.trix-button--icon-decrease-nesting-level,
#editor_de button.trix-button--icon-increase-nesting-level {
  display: none;
}
trix-editor.trix-content {
  background-color: #ffffff !important;
  min-height: 200px;
}
</style>
